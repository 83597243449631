/** @jsx jsx */
import PropTypes from 'prop-types';
import { Text } from '@rebass/emotion';
import { jsx } from '@emotion/core';

const Alert = ({ theme, success, children, ...props }) => {
  return (
    <Text css={theme => ({
        lineHeight: '1.6em',
        backgroundColor: success ? 'rgba(122, 223, 201, 0.2)' : 'rgba(236, 87, 87, 0.5)',
        color: success ? 'rgb(122, 223, 201)' : 'rgb(236, 87, 87)',
        padding: '24px',
        borderRadius: '4px',
      })}
      {...props}
      fontSize={[0, 1, 1]}
    >
      {children}
    </Text>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Alert;
