/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component } from 'react';

const input = css`
  width: 100%;
  height: 48px;
  position: relative;
  padding: 0px;
  border: none;
  border-radius: 0px;
  font-size: 18px;
  line-height: normal;
  background-color: transparent;
  color: #FFFFFF;
  outline: none;
  transition: 0.1s padding ease-in-out;
  -webkit-appearance: none;
  ::placeholder {
    color: rgba(255,255,255,0.7);
  }
  @media (min-width: 40em) {
    font-size: 22px;
    height: 60px;
  }
`;

const textareastyle = css`
  width: 100%;
  height: 130px;
  position: relative;
  padding: 16px 0;
  border: none;
  border-radius: 0px;
  font-size: 18px;
  line-height: normal;
  background-color: transparent;
  color: #FFFFFF;
  outline: none;
  transition: 0.1s padding ease-in-out;
  -webkit-appearance: none;
   resize: none;
   ::placeholder {
     color: rgba(255,255,255,0.7);
   }
   @media (min-width: 40em) {
     font-size: 22px;
     height: 160px;
   }
`;

const field = css`
  border-bottom: 1px solid rgba(255,255,255,0.4);
  width: 100%;
  height: auto;
  position: relative;
  background-color: transparent;
  transition: 0.3s border-color ease-in-out;
  input + label, textarea + label {
    position: absolute;
    top: 24px;
    left: 0px;
    font-size: 13px;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }
`;

const activeStyle = css`
  border-color: #ffffff;
  input, textarea {
    padding: 28px 0px 8px 0px;
  }
  input + label, textarea + label {
    top: 2px;
    opacity: 1;
    color: rgba(255,255,255,0.7);
  }
  @media (min-width: 40em) {
    input + label, textarea + label {
      top: 4px;
    }
  }
`

const lockedStyle = css`
  pointer-events: none;
`

class TextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || "",
      error: props.error || "",
      label: props.label || "Label"
    };
  }

  changeValue(event) {
    const value = event.target.value;
    this.setState({ value, error: "" });
  }

  render() {
    const { active, value, error, label } = this.state;
    const { locked, name, id, type, textarea, ...rest } = this.props;
    const fieldStyles = [field, (locked ? active : active || value) &&
      activeStyle, locked && !active && lockedStyle];

    return (
      <div css={[fieldStyles]}>
        {!textarea &&
          <input
            css={input}
            id={id}
            type={type}
            value={value}
            name={name}
            placeholder={label}
            onChange={this.changeValue.bind(this)}
            onFocus={() => !locked && this.setState({ active: true })}
            onBlur={() => !locked && this.setState({ active: false })}
            {...rest}
          />
        }
        {textarea &&
          <textarea
            css={textareastyle}
            id={id}
            value={value}
            name={name}
            placeholder={label}
            onChange={this.changeValue.bind(this)}
            onFocus={() => !locked && this.setState({ active: true })}
            onBlur={() => !locked && this.setState({ active: false })}
            noResize
            {...rest}
          />
        }
          <label htmlFor={id} className={error && "error"}>
            {error || label}
          </label>
      </div>
    );
  }
}

export default TextField;
