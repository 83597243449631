import React, { Component } from 'react';
import { Box, Flex, Link as EmoLink } from '@rebass/emotion';
import styled from '@emotion/styled';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Heading from '../components/Heading';
import Text from '../components/Text';
import Wrap from '../components/Wrap';
import TextField from '../components/TextField';
import Alert from '../components/Alert';

const Submit = styled('input')`
  appearance: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border-radius: ${props => props.theme.borderRadius[0]};
  display: inline-block;
  font-size: 22px;
  text-decoration: none;
  border: ${props => props.theme.borders.white};
  color: ${props => props.theme.colors.white};
  padding: 18px 24px;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: ${props => props.theme.colors.white};
    border: ${props => props.theme.borders.white};
    color: ${props => props.theme.colors.darkGray};
  }
`;

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      message: '',
      successful: false,
    };
  }

  handleSubmit = e => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state })
      })
        .then(() => {
            this.setState({
              successful: true,
              name: '',
              email: '',
              message: '',
            });
        })
        .catch(error => alert(error));

      e.preventDefault();
  };

  handleChange = e => {
    this.setState({ [e.target.name]: [e.target.value]});
  };

  render() {
    const { name, email, message } = this.state;
    const { location } = this.props;
    return(
      <Layout location={location}>
        <SEO title="Citra. Get In Touch" keywords={[`contact`, `ux`, `product`, `design`, `studio`]} />
        <Box>
          <Wrap flexWrap="wrap" py={['12%', '6%']}>
            <Box order={1} data-aos="fade-up" mb={[1, 5]} width={[1, 5/10, 5/10]}>
              <Heading as="h2" fontSize={[5,6,8]} mb={2} color="white">Get In Touch</Heading>
                <Text color="white" fontSize={[ 1, 3, 4 ]} mb={4}>We’d love to hear from you! Send us a message and tell us about your project.</Text>
            </Box>
            <Box order={[3, 2, 2]} data-aos="fade-up" data-aos-delay="100" width={[1, 6/10, 6/10]}>
              {this.state.successful &&
                <Alert mb={3} data-aos="fade-up" success>Thanks for getting in touch, we'll get back to you soon!</Alert>
              }
              <form id="contact" name="contact" onSubmit={this.handleSubmit.bind(this)} data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact" />
                <Flex mb={4} flexWrap="wrap">
                  <Box mb={2} width={1}>
                      <TextField label="Your Name" value={name} onChange={this.handleChange.bind(this)} type="text" name="name" id="name" />
                  </Box>
                  <Box mb={2} width={1}>
                      <TextField label="Your Email" value={email} onChange={this.handleChange.bind(this)} type="email" name="email" id="email" />
                  </Box>
                  <Box width={1}>
                      <TextField textarea value={message} onChange={this.handleChange.bind(this)} label="Tell us about your project" name="message" id="message" rows="6" />
                  </Box>
                </Flex>
                <Submit type="submit" value="Send Message" />
              </form>
            </Box>
            <Box order={[2, 3, 3]} mb={[3, 0]} data-aos="fade-up" data-aos-delay="100" py={['10%', 0]} ml={[0, '10%']} width={[1, 3/10]}>
              <Flex flexWrap="wrap">
                <Box width={[1/2, 1]} mt={[0, 5]}>
                  <Text color="lightGray" fontSize={['14px', 0]}>Email Us</Text>
                  <EmoLink color="white" fontSize={[1, 2]} href="mailto:hello@citra.co">hello@citra.co</EmoLink>
                </Box>
                <Box width={1} mt={[5, 5]}>
                  <Text fontSize={['14px', 0]} color="lightGray">Visit Us</Text>
                  <Text color="white" fontSize={[1, 2]} mb={2} lineHeight={'1.125em'}>80 M Street SE<br />1st Floor<br />Washington, DC 20003</Text>
                  <EmoLink color="white" fontSize={[1, 2]} href="https://goo.gl/maps/wSm4nEutc5qaVWTC7" target="_blank">Map</EmoLink>
                </Box>
              </Flex>
            </Box>
          </Wrap>
        </Box>
      </Layout>
    )
  }
}

export default Contact;
